import { Strings } from "../../libs/Strings";
import Handlebars from "handlebars";

export class Helpers {
    public static init(): void {
        Handlebars.registerHelper( "includes", helperIncludes );
        Handlebars.registerHelper( "_if", helperIfBasic );
        Handlebars.registerHelper( "_ifCond", helperConditions );
        Handlebars.registerHelper( "_if_advanced", helperIfAdvanced );
        Handlebars.registerHelper( "regex", helperRegex );
        Handlebars.registerHelper( "starts_with", helperStartsWith );
        Handlebars.registerHelper( "replace_all", helperReplaceAll );
        Handlebars.registerHelper( "cut_all", helperCutAll );
        Handlebars.registerHelper( "cut", helperCut );
        Handlebars.registerHelper( "cut_after_last", helperCutAfterLast );
        Handlebars.registerHelper( "check_length", helperCheckLength );
        Handlebars.registerHelper( "maths", helperMaths );
        Handlebars.registerHelper( "contains", helperContains );
        Handlebars.registerHelper( "ifCond", helperIfCondition );
        Handlebars.registerHelper( "counter", helperCounter );
        Handlebars.registerHelper( "length", helperLength );
        Handlebars.registerHelper('eq', helperEqual);
        Handlebars.registerHelper('or', helperOr);
    }
}

function helperIncludes( string: string, array: string, options: any ) {
    return Strings.toArray( array ).indexOf( string ) > -1 ? options.fn(this) : options.inverse(this);
}

function helperIfBasic( paramOne: any, operation: string, paramTwo: any, options: any ): string {
    return helperIf( paramOne, operation, paramTwo ) ? options.fn(this) : options.inverse(this);
}

function helperIfAdvanced( paramOne: any, paramOneByID: string, operation: string, paramTwo: any, paramTwoByID: string, options: any ): string {
    if ( paramOneByID == "true" ) {
        paramOne = getInputValue( paramOne );
    }
    if ( paramTwoByID == "true" ) {
        paramTwo = getInputValue( paramTwo );
    }

    return helperIf( paramOne, operation, paramTwo ) ? options.fn(this) : options.inverse(this);
}

function helperRegex( string: string, regex: string, options: any ): string {
    var expression: RegExp = new RegExp( regex );

    if ( expression.exec( string ) ) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
}

function helperStartsWith( paramOne: string, paramTwo: string, options: any ): string {
    if ( Strings.startsWith(paramOne, paramTwo) ) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
}

function helperCheckLength( value: string, start: number, end: number, options: any ): string {
    return value.length > start && value.length < end ? options.fn(this) : options.inverse(this);
}

function helperReplaceAll( string: string, searchFor: string, replaceWith: string ): string {
    return string.replace( new RegExp( searchFor, "g" ), replaceWith );
}

function helperCut( string: string, searchFor: string ): string {
    var searchForArray = Strings.toArray( searchFor );

    for ( let i = 0; i < searchForArray.length; i++ ) {
        string = string.replace( searchForArray[i], "" );
    }

    return string;
}

function helperCutAll( string: string, searchFor: string ): string {
    var searchForArray = Strings.toArray( searchFor );

    for ( let i = 0; i < searchForArray.length; i++ ) {
        string = string.replace( new RegExp( searchForArray[i], "g" ), "" );
    }

    return string;
}

function helperCutAfterLast( string: string, cutAfter: string ): string {
    return string.substring( 0, string.lastIndexOf( cutAfter ) );
}

function helperMaths( numberOne: number, operation: string, numberTwo: number, options: any ): string {
    let result = "";
    let number: number = 0;

    switch( operation ) {
        case "+":
            number = numberOne + numberTwo;
            break;
        case "-":
            number = numberOne - numberTwo;
            break;
        case "*":
            number = numberOne - numberTwo;
            break;
        case "/":
            number = numberOne / numberTwo;
            break;
        case "^":
            number = Math.pow( numberOne, numberTwo );
            break;
    }

    return number.toString();
}

function helperIf( paramOne: any, operation: string, paramTwo: any ): boolean {
    let isTrue: boolean = true;

    if ( operation == "<" || operation == ">" ) {
        let numberOne: number;
        let numberTwo: number;

        if( isNaN( paramOne ) ) {
            numberOne = paramOne.length;
        } else {
            numberOne = Number( paramOne );
        }

        if ( isNaN( paramTwo ) ) {
            numberTwo = paramTwo.length;
        } else {
            numberTwo = Number( paramTwo );
        }

        switch( operation ) {
            case ">":
                isTrue = numberOne > numberTwo;
                break;
            case "<":
                isTrue = numberOne < numberTwo;
                break;
        }
    } else {
        switch( operation ) {
            case "==":
                isTrue = paramOne == paramTwo;
                break;
            case "!=":
                isTrue = paramOne != paramTwo;
                break;
        }
    }

    return isTrue;
}

function getInputValue( elementID: string ): string {
    var element: HTMLElement = document.getElementById( elementID );

    try {
        return element.innerHTML;
    } catch (error) {
        return "";
    }
}

function helperContains( paramOne: string, paramTwo: string, options: any ) {
    if ( paramOne.includes( paramTwo ) ) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
}

function helperConditions( v1: any, operator: any, v2: any, options: any ) {
    switch( operator ) {
        case "==":
            return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case "===":
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case "!=":
            return (v1 != v2) ? options.fn(this) : options.inverse(this);
        case "!==":
            return (v1 !== v2) ? options.fn(this) : options.inverse(this);
        case "!===":
            return (v1 !== v2) ? options.fn(this) : options.inverse(this);
        case "<":
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case "<=":
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case ">":
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case ">=":
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case "&&":
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case "||":
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
}

function helperCounter( index: any ) {
    return index + 1;
}

function helperIfCondition( v1: any, operator: any, v2: any, options: any ) {
    switch (operator) {
        case '==':
            return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case '!=':
            return (v1 != v2) ? options.fn(this) : options.inverse(this);
        case '!==':
            return (v1 !== v2) ? options.fn(this) : options.inverse(this);
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
}

function helperLength (obj:any):number {
    return obj.length;
}
function helperEqual(a:any, b:any) {
    // console.log('eq helper called with', a, b);
    return a === b;
};

function helperOr() {
    var args:Array<any> = Array.prototype.slice.call(arguments);
    // console.log('or helper called with', arg1, arg2);
    // console.log('this:', this);
    args.pop(); // might need to be changed accordingly.
    if (args.some(Boolean)) {
        // console.log('or helper returning true');
        return this;
    }
}