import { Elements } from "./Elements";
import { Globals } from "../classes/Globals";

export class Overlay {
    private element: HTMLElement;
    private blockedBy: string;

    public constructor() {
        this.element = document.createElement("div");
        this.element.setAttribute( Globals.ATTRIBUTE_PREFIX + "overlay", "" );
        this.blockedBy = null;
    }

    public create( selector: string ): void {
        document.querySelector( selector ).prepend( this.element );
        Elements.hideElement( jQuery(this.element) );
    }

    public openOverlay( modulename: string = null, animation: string = "" ): void {
        if ( modulename != null ) {
            this.blockedBy = modulename;
            Elements.clearAnimation( this.element );
            Elements.showElement( jQuery(this.element), animation );
        }
    }

    public closeOverlay( modulename: string, animation: string = "" ): void {
        if ( modulename != null && modulename == this.blockedBy ) {
            this.blockedBy = null;
            Elements.hideElement( jQuery(this.element), animation );
        }
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}