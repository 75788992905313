export class Event {
    private eventname: string;
    private functions: Array<() => any>;

    public constructor( eventname: string ) {
        this.eventname = eventname;
        this.functions = new Array();
    }

    public getEventname(): string {
        return this.eventname;
    }

    public getFunctions(): Array<() => void> {
        return this.functions;
    }

    public bind( callback: () => void ): void {
        this.functions.push( callback );
    }
}