import { Json } from "./../../libs/Json";
import { Controller } from "./Controller";
import { Strings } from "../../libs/Strings";
import { Pair } from "./../../libs/Pair";
import { Windows } from "./../../libs/Windows";
import { Event } from "./../Event";
import { Method } from "./../Method";
import { findIcon } from "./../../libs/Icons";
import { View } from "./View";
import { Model } from "./Model";
import { modules } from "./../../main";
import { Globals, ModuleExecutionStatus } from "./../Globals";
import { Times } from "../../libs/Times";

export abstract class Module<ModelType extends Model> {
	private static moduleAccess:Array<Pair<string, number>> = new Array();
	private static UIDModuleAccess:number = 0;
		
	private name:string;

	private accessID:number;

	private components:Object;
	private labels:Object;
	private configs:Object;
	private templates:Object;
	
	private active:boolean;

	private controllers:Array<Controller<ModelType>>;
	private views:Array<View>;

	private events:Array<Event>;
	private methods:Array<Method>;

	private globalConfigs:Array<Pair<string, any>>;
	private dependentModules:Array<Pair<string, Controller<Model>>>;

	private flagError:boolean;
	private flagUseCookies:boolean;
	
	private flagInitializing:boolean;
	private flagInitialized:boolean;

	private flagGlobalsInitialized:boolean;

	private executionTime:number;
	private executionStatus:ModuleExecutionStatus;

	private data:any;

    public constructor ( name:string, configuration:Object, flagUseCookies:boolean = false ) {
		this.name = name;

		this.active = false;

		this.controllers = new Array();
		this.views = new Array();

		this.events = new Array();
		this.methods = new Array();

		this.globalConfigs = new Array();
		this.dependentModules = new Array();

		this.flagError = false;
		this.flagUseCookies = flagUseCookies;
		
		this.flagInitializing = false;
		this.flagInitialized = false;

		this.flagGlobalsInitialized = false;

		this.executionTime = 0;
		this.executionStatus = null;

        if ( configuration != null ){

            let components = Json.getSubobject( configuration, "components" );
            let labels = Json.getSubobject( configuration, "labels" );
            let configs = Json.getSubobject( configuration, "config" );
			let templates = Json.getSubobject( configuration, "templates" );

            if( components != null && labels != null && configs != null ){
               
                this.components = components;
                this.labels = labels;
				this.configs = configs;
				this.templates = templates;

				this.accessID = this.registerControllerAccess();
				this.active = Json.getSubobject( configuration, "active" ) == true;

				this.data = Json.getSubobject( window, "ezentrum_data." + this.name );

				Module.moduleAccess.push( new Pair( this.name, this.accessID ) );

				this.registerCallableMethod( this, "reInit", this.reInit.bind(this));
            }

        }
	}

    public getConfig ( key:string ):any {
		return Json.getSubobject( this.configs, key );
	}
	public getLabel ( key:string ):string {
		var label = Json.getSubobject( this.labels, modules.getLanguageCode() + "." + key );

		if ( typeof label === "string" ) {
			return label;
		} else {
			return null;
		}
	}
	public getAllLabels(): Object {
		var labels = Json.getSubobject( this.labels, modules.getLanguageCode() );

		return labels;
	}
	public getHTMLTemplate( templateName: string ): string {
		if ( templateName !== "" ) {
			var template = Json.getSubobject( this.templates, templateName );

			return template;
		} else {
			return null;
		}
	}
	public getComponent ( key:string, unisex:boolean = false ):any {
		var path:string = key;

		// if ( unisex ) {
		// 	path = "unisex." + key;
		// } else {
		// path = modules.getTemplate() + "." + key
		//}

		path = modules.getTemplate() + "." + key

		var component:any = Json.getSubobject( this.components, path );
		var result:any = result = this.prepareReplaceKeywords.call( this, component, key );

		return result;
	}

	private prepareReplaceKeywords ( component:any, componentKey:string ):any {
		let result:any = component;

		switch ( typeof component ){
			case "string":
				result = this.replaceKeywords( component, componentKey );
			break;
			case "object":
				for ( var key in result ){
					result[ key ] = this.prepareReplaceKeywords( result[ key ], componentKey );
				}
			break;
		}

		return result;
	}

	private replaceKeywords ( component:string, componentKey:string ):any{
        if ( component != null ) {
			
			const regex = /\[(label|component|config|icon):([a-zA-Z0-9\\-\_\.]*)\]/g;
			const search_in = component; // !!!--- Its important to cast the component variable into an constance ---!!!

			var match;
			
			while ( ( match = regex.exec( search_in ) ) !== null ) {
					
				if ( match.index === regex.lastIndex ) {
					regex.lastIndex++;
				}
				
				var replacement = match[0];
				var key = match[1];
				var value = match[2];

				var replace_with = null;

				switch ( key ){
					case "label":
						replace_with = this.getLabel( value );
						break;
					case "config":
						replace_with = this.getConfig( value );
						break;
					case "component":
						var is_unisex = false;
						if ( Strings.startsWith( value, "unisex." ) ) {
							value = value.substring(7);
							is_unisex = true;
						}

						/**
						 * 
						 * no avoid a endless loop
						 */
						if ( componentKey != key ) {
							var foundComponent = this.getComponent( value, is_unisex );
							if ( typeof foundComponent === "string" || typeof foundComponent === "number" || typeof foundComponent === "boolean" ) {
								replace_with = foundComponent;
							}
						}
						break;
					case "icon":
						replace_with = findIcon( value );
						break;
					}

				if ( replace_with !== null ) {
					component = component.replace( replacement, replace_with );
				}	

			}

			return component;
		} else {
			return null;
		}
	}

	/**
	 * 
	 * Event Binding
	*/
	public registerEvent ( eventname:string ){
		this.events.push( new Event( eventname ) );
	}

	public executeEvent ( eventname:string ){
		for (let i = 0; i < this.events.length; i++) {
			if ( this.events[i].getEventname() == eventname ){
				for (let i = 0; i < this.events[i].getFunctions().length; i++) {
					this.events[i].getFunctions()[i]();
				}
				break;
			}
		}
	}
	
	public bindEvent ( eventname:string, callback:() => void ){
		this.events.forEach(event => {
			if ( event.getEventname() == eventname ){
				event.getFunctions().push( callback );
			}
		});
	}

	/**
	 * 
	 * Method call
	*/
	public registerCallableMethod ( caller:any, modulename:string, callback: ( ...args:any[] ) => void ){
		this.methods.push( new Method( caller, modulename, callback ) );
	}

	public callMethod ( methodname:string, args:Array<any> ):any{
		var result:any = null;

		for (let i = 0; i < this.methods.length; i++) {
			if ( this.methods[i].getMethodname() == methodname ){
				try {
					var functionReturn = this.methods[i].getMethod().apply( this.methods[i].getCaller(), args );
					if ( functionReturn !== undefined ){
						result = functionReturn;
					}
				} catch (e) {
					this.error( "Folgende Funktion konnte nicht aufgerufen werden oder ist Fehlerhaft: " + this.name + "." + this.methods[i].getMethodname() );
				}

				break;
			}
		}

		return result;
	}

	/**
	 * Method: addView
	 * Add one handlebar template to the module.
	 * It will be used for processing the template with the data-object.
	 * If the "name" will exist, it will not be added.
	 * 
	 * Result: true -> View is added successfully.
	 * Result: false -> View is not added, because template is null or the view still exists.
	*/
	public addView ( name:string, template:string ):boolean{
        if ( template != null ){
            let templateExists:boolean = false;
            this.views.forEach(view => {
                if( view.getName() == name ){
                    templateExists = true;
                }
            });

            if( !templateExists ){
                this.views.push( new View( name, template ) );

                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
        
	}

	public getView ( templatename:string ):View{
		let result:View = null;

		this.views.forEach(element => {
            if( element.getName() == templatename ){
               result = element;
            }
		});
		
		return result;
	}

	public clearViews ():void{
		this.views = new Array();
	}

	/**
	 * 
	 * Controller
	 */
	public addController ( controller:Controller<ModelType> ):void {
		this.controllers.push( controller );
	}

	public getControllers ():Array<Controller<ModelType>> {
		return this.controllers;
	}

	private registerControllerAccess ():number{
		Module.UIDModuleAccess++;
		
		for (let i = 0; i < Module.moduleAccess.length; i++) {
			if ( this.name != "Menu" ) {
				if ( Module.moduleAccess[i].getKey() == this.name ){
					Module.moduleAccess[i].setValue( Module.UIDModuleAccess );
				}
			}
		}

		return Module.UIDModuleAccess;
	}

	public static checkControllerAccess ( moduleName:string, id:number ){
		var hasAccess:boolean = false;

		for (let i = 0; i < Module.moduleAccess.length; i++) {
			if ( Module.moduleAccess[i].getKey() == moduleName && Module.moduleAccess[i].getValue() == id ){
				hasAccess = true;

				break;
			}
		}

		return hasAccess;
	}

	public runAllControllers ():void {
		var startTime:number = new Date().getTime();
		var oneControllerInitalized:boolean = false;

		for (let i = 0; i < this.getControllers().length; i++) {
			if ( !this.getControllers()[i].isInitialized() ){
				oneControllerInitalized = true;
			}

			this.getControllers()[i].runWithPreCheck();
		}

		if ( oneControllerInitalized ){
			for (let i = 0; i < this.dependentModules.length; i++) {
				modules.callMethod( this.dependentModules[i].getKey(), "reInit", this.dependentModules[i].getValue() );
			}
			
			this.onControllersInitialized();
		}

		this.executionTime += new Date().getTime() - startTime;
	}

	public runAllInitializedControllers ():void{
		for (let i = 0; i < this.getControllers().length; i++) {
			if ( this.getControllers()[i].isInitialized() ){
				this.getControllers()[i].runWithGlobalsPrecheck();
			}
		}
	}

	/**
	 * 
	 * Global configs
	 */
	public getGlobalConfig ( key:string ):any{
		var result:any = null;

		for (let i = 0; i < this.globalConfigs.length; i++) {
			if( this.globalConfigs[i].getKey() == key ){
				result = this.globalConfigs[i].getValue();

				break;
			}
		}

		return result;
	}
	public setGlobalConfig ( key:string, value:string ):void{
		var exists:boolean = false;

		for (let i = 0; i < this.globalConfigs.length; i++) {
			if( this.globalConfigs[i].getKey() == key ){
				exists = true;
			}
		}

		if ( !exists ){
			this.globalConfigs.push( new Pair( key, value ) );
		}
	}

	/**
	 * Call global module event function
	 * @param module 
	 * @param eventName 
	 */
	public callGlobalModuleEventFunction ( eventName:string, throwError:boolean = false ):void{
		Windows.callGlobalFunction( "on" + this.getName() + Strings.beginWithUppercase( eventName ), throwError )
    }

	public addModuleAttribute( rootElement:JQuery<HTMLElement> ):void{
		if ( rootElement.length ){
			rootElement.attr( Globals.MODULE_ATTRIBUTE_KEY + this.getName().toLowerCase(), "" );
		}
	}

	public error ( message:string, useStrackTrace:boolean = false ){
		modules.error( "Fehler im Modul " + this.name + ": " + message, useStrackTrace );
		this.flagError = true;
	}

	public log ( message:string ){
		modules.log( "Log vom Modul " + this.name + ": " + message );
		this.flagError = true;
	}

	public reInit():void{
		this.runAllInitializedControllers();
	}

	public runWithPreCheck ():void{
		if ( this.readyToRun() ){
			this.flagInitializing = true;

			this.run();

			this.flagInitializing = false;
			this.flagInitialized = true;
		}
	}

	public readyToRun ():boolean {
		return !this.flagInitialized && !this.flagInitializing;
	}

	/**
	 * 
	 * Dependent module
	 */
	public registerDependentModule ( moduleName:string, controller:Controller<Model> ){
		this.dependentModules.push( new Pair( moduleName, controller ) );
	}

	public printInfo ():void{
		console.log( "\tInstanzen: " + this.controllers.length );
    	console.log( "\tZeit: " + Times.getTimeString(this.executionTime, "s") );
	}


	/**
	 * 
	 * SETTER
	 */
	public setGlobalsInitialized (initialized:boolean):void{
		this.flagGlobalsInitialized = initialized;
	}
	public setInitializationCompleted ():void{
		this.flagInitialized = true;
		this.flagInitializing = false;
	}

	public setExecutionStatus( status:ModuleExecutionStatus ):void{
		this.executionStatus = status;
	}

	/**
	 * 
	 * GETTER
	 */
	public getName ():string{
		return this.name;
	}

	public getAccessID ():number{
		return this.accessID;
	}

	public isActive ():boolean{
		return this.active;
	}

	public isInitialized ():boolean{
		return this.flagInitialized;
	}

	public isInitializing ():boolean{
		return this.flagInitializing;
	}

	public areGlobalsInitialized ():boolean{
		return this.flagGlobalsInitialized;
	}

	public useCookies ():boolean {
		return this.flagUseCookies;
	}

	public foundError ():boolean{
		return this.flagError;
	}

	public getExecutionTime ():number{
		return this.executionTime;
	}

	public getExecutionStatus():ModuleExecutionStatus{
		return this.executionStatus;
	}

	public getData():any{
		return this.data;
	}

	/**
	 * 
	 * Abstract methods
	*/
	public abstract run():void;
	public abstract onControllersInitialized():void;
}