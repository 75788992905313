export class Interfaces {
    public static implements( object: any, properties: Array<string> ): boolean {
        let count: number = 0;

        for ( let i = 0; i < properties.length; i++ ) {
            if ( typeof object[ properties[i] ] === "function" ) {
                count++;
            } else {
                break;
            }
        }

        return count == properties.length;
    }
}