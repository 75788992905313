import { OperatingSystem } from "../classes/Globals";

export class OperatingSystems {
    public static get(): OperatingSystem {
        if ( navigator.userAgent.match( /Macinstosh|MAC_PowerPC/i ) ) {
            return OperatingSystem.OSX;
        } else if ( navigator.userAgent.match( /Windows/i ) ) {
            return OperatingSystem.WINDOWS;
        } else if ( navigator.userAgent.match( /Linux/i ) ) {
            return OperatingSystem.LINUX;
        } else {
            return OperatingSystem.DEFAULT;
        }
    }
}