export class Method {
    private caller: any;

    private methodname: string;
    private method: ( ...args: any[] ) => any;

    public constructor( caller: any, methodname: string, method: ( ...args:any[] ) => any ) {
        this.caller = caller;

        this.methodname = methodname;
        this.method = method;
    }

    public getMethodname(): string {
        return this.methodname;
    }

    public getMethod(): ( ...args: any[] ) => void {
        return this.method;
    }

    public getCaller() {
        return this.caller;
    }
}