export class LogEntry {
    private message: any;
    private outputStyle: any;

    public constructor( message: any, outputStyle: any ) {
        this.message = message;
        this.outputStyle = outputStyle;
    }

    public getMessage(): any {
        return this.message;
    }

    public getOutputStyle(): any {
        return this.outputStyle;
    }
}