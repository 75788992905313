import { Globals } from "../classes/Globals";

import jQuery from "jquery";

export class Elements {
    public static showElement( element: JQuery<HTMLElement>, animation: string = "" ): void {
        if ( element ) {
            element.attr( Globals.ATTRIBUTE_PREFIX + "open", "true" );
            this.animate( element, animation );
        }
    }

    public static hideElement( element: JQuery<HTMLElement>, animation: string = "" ): void {
        if ( element ) {
            element.attr( Globals.ATTRIBUTE_PREFIX + "open", "false" );
            this.animate( element, animation );
        }
    }

    public static animate( element: JQuery<HTMLElement>, animation: string ): void {
        if ( animation != "" ) {
            element.attr( Globals.ATTRIBUTE_PREFIX + "animation", animation );
        }
    }

    public static clearAnimation( element: HTMLElement ): void {
        element.setAttribute( Globals.ATTRIBUTE_PREFIX + "animation", "" );
    }

    public static isSelectBox( element: JQuery<HTMLElement> ): boolean {
        return element.is( "select" );
    }

    public static isCheckbox( element: JQuery<HTMLElement> ): boolean {
        return element.is( ":checkbox" );
    }

    public static isRadioBox( element: JQuery<HTMLElement> ): boolean {
        return element.is( ":radio" );
    }

    public static setFocusOnClick(): void {
        let eventValue: string = "setFocus";
        var elements:any = jQuery( "input:checkbox:not([" + Globals.EVENT_SET_ATTRIBUTE + "='" + eventValue + "']), input:radio:not([" + Globals.EVENT_SET_ATTRIBUTE + "='" + eventValue + "'])" );

        if ( elements.length > 0 ) {
            for ( let i = 0; i < elements.length; i++ ) {
                jQuery( elements[i] ).on('change', function() {
                    var element: JQuery<HTMLElement> = jQuery( this );
                    if ( !element.is( ":focus" ) ) {
                        element.trigger( 'focus' );
                    }
                })

                jQuery( elements[i] ).attr( Globals.EVENT_SET_ATTRIBUTE, eventValue );
            }
        }
    }

    public static blockSpecialChars(): void {
        const regex = /((?![a-zA-Z0-9üäöÜÄÖß!§$%&()=?+#,.*’;:_<>@€\s\“\"\'\\\/\-\/\[\]\{\}]).)/g;

        let eventValue: string = "blockSpecialChars";
        var elements:any = jQuery( "input:password:not([" + Globals.EVENT_SET_ATTRIBUTE + "='"+ eventValue +"'])" );

        if ( elements.length > 0 ) {
            for (let i = 0; i < elements.length; i++) {
            
                elements[i].addEventListener( "input", function (){
                    this.value = this.value.replace( regex, "" );
                });
    
                jQuery( elements[i] ).on("paste", function ( event:any ){
                    if ( event.originalEvent.clipboardData.getData("text/plain").match( regex ) ){
    
                        alert("Sie können keine Ungültigen Zeichen als Passwort verwenden.");
    
                        event.preventDefault();
                    }
                });
    
                jQuery( elements[i] ).attr( Globals.EVENT_SET_ATTRIBUTE, eventValue );
            }
        }
    }

    public static addComment( element: JQuery<HTMLElement>, comment: string ) {
        if ( element ) {
            element.before( "<!-- " + comment + "-->" )
        }
    }

    public static findElement( findIn: JQuery<HTMLElement>, event: string ) {
        let element = findIn.find( "[" + Globals.ELEMENT_ATTRIBUTE + "='" + event + "']" );

        if ( element.length ) {
            return element;
        } else {
            return null;
        }
    }
}