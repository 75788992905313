// Abstract Class: EZ-Module
// Central abstract class for the ezentrum modules V2. 
// All eZentrum modules V2 need to extend this class. 
// The ezentrum modul system will load the V2 modules by executing at first the init() and then the run() method. 
import { Json } from "../../libs/Json";

export abstract class EZModule {
    // TODO: in private umbauen
    public static configs: object = {}; // Modul-Configurations
    public static labels: object = {}; // Modul-Labels
    public moduleName: string = ""; // Modul-Name

    // constructor
    public constructor(configuration: any) {
    }

    // getConfig
    // returns the module configuration object
    public static getConfig(key: string): any {
        return Json.getSubobject(this.configs, key);
    }

    // getLabel
    // returns the label identified by key
    public static getLabel(key: string): string | null {
        let label = Json.getSubobject(this.labels, this.getLanguageCode() + "." + key);
        if (typeof label === "string") {
            return label;
        } else {
            return null;
        }
    }

    // getLanguageCode
    // returns the html-language iso-code from the current website
    public static getLanguageCode(): string | null {
        return document.documentElement.lang;
    }

    // init()
    // General ezentrum modul init
    abstract init(): void;

    // run()
    // General run method for the html-rendering + events assignment
    abstract run(): void;
    
}
