import { Modules } from "./Modules";

export var modules: Modules = new Modules();

modules.preInit();
if ( modules.checkRequirements() ) {
    document.addEventListener("DOMContentLoaded", function() {
        modules.init();
    });
}

declare var window: any;
window.ezmodules = modules;