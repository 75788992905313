import { Elements } from "../libs/Elements";
import { Globals } from "./Globals";

import jQuery from "jquery";

export class Collapse {
    public static init(): void {
        /**
        * 
        * Collapse open and close events
        */
        var event: string = "onCollapse";
        var collapses = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "open]:not([" + Globals.EVENT_SET_ATTRIBUTE + "='" + event + "'])" );
        for ( var i = 0; i < collapses.length; i++ ) {
            var collapse = jQuery( collapses[i] );

            collapse.on( "click", this.collapseToggle.bind( this, collapse ) );
            this.collapseCheckOpen( collapse );

            collapse.attr( Globals.EVENT_SET_ATTRIBUTE, event );
            Elements.addComment( collapse, "Collapse: ./src/classes/Collapse.ts" );
        }
    }

    /**
     * 
     * Toggle the collapse
     */
    private static collapseToggle( item: JQuery<HTMLElement> ): void {
        var content = jQuery( "#" + item.attr( Globals.ATTRIBUTE_PREFIX + "open" ) );
        if ( content.length ) {
            if ( content.is(":visible") ) {
                content.slideUp();
                item.attr( "is-open", "false" );
            } else {
                content.slideDown();
                item.attr( "is-open", "true" );
            }
        }
    }

    /**
     * 
     * Set the data-open attributes
     */
    private static collapseCheckOpen( item:JQuery<HTMLElement> ): void {
        var content = jQuery( "#" + item.attr( Globals.ATTRIBUTE_PREFIX + "open" ) );
        if ( content.length ) {
            if ( content.is( ":visible" ) ) {
                item.attr( "is-open", "true" );
            } else {
                item.attr( "is-open", "false" );
            }
        }
    }
}