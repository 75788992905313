export class Json {
    public static getSubobject( object: any, subobject: string ): any {
        if ( object !== undefined && object !== null ) {

            var elements: Array<string> = subobject.split( "." );
            var result: any = object;

            for ( var i = 0; i < elements.length; i++ ) {
                var element: string = elements[i];

                if ( result[ element ] !== undefined ) {
                    result = result[ element ];
                } else {
                    result = null;
                    break;
                }
            }

            return result;
        } else {
            return null;
        }
    }

    public static setSubobject( object: any, subobject: string, value: any ): void {
        if ( object !== undefined && object !== null ) {
            var elements: Array<string> = subobject.split( "." );
            var result: any = object;

            for ( var i = 0; i < elements.length; i++ ) {
                var element: string = elements[i];

                if ( i == elements.length - 1 ) {
                    result[ element ] = value;
                } else {
                    if ( result[ element ] === undefined ) {
                        result[ element ] = {};
                    }

                    result = result[ element ];
                }
            }
        }
    }

    public static convertObjectToArray( items: any ):Array<any> {
        if ( items != null && !( items instanceof Array ) ) {
            var items_tmp:Array<any> = new Array();
            items_tmp[0] = items;

            return items_tmp;
        } else {
            return items;
        }
    }
}