import { Pair } from "./Pair";
import { Times } from "./Times";

export class Cookies {
    public static instance: Cookies;

    private cookies: Array<Pair<string, string>> = new Array();

    private constructor() {
        var cookieString: string = document.cookie || "";
        var cookieArray: Array<string> = cookieString.replace( new RegExp( "; ", "g" ), ";" ).split(";");

        for (let i = 0; i < cookieArray.length; i++) {
            var keyValueArray = cookieArray[i].split("=");

            if ( keyValueArray.length == 2 ) {
                var key: string = keyValueArray[0];
                var value: string = keyValueArray[1];

                this.cookies.push( new Pair( key, value ) );
            }
        }
    }

    public static start(): void {
        Cookies.instance = new Cookies();
    }

    public setWildcard( key: string, value: string, expiresIn: string = "1d" ): void {
        this.setCookie( key, value, true, expiresIn );
    }

    public set( key:string, value: string, expiresIn: string = "1d" ): void {
        this.setCookie( key, value, false, expiresIn );
    }

    private setCookie( key: string, value: string, wildcard: boolean, expiresIn: string ): void {
        if ( this.get( key ) == null ) {
            var cookie: string = "";
            var hostname = window.location.hostname;

            cookie = key + "=" + value + ";path=/";

            if ( wildcard && !hostname.match( "[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}" ) ) {
                var hostnameItems = hostname.split( "." );

                if ( hostnameItems.length >= 2 ) {
                    var domain: string = hostnameItems[ hostnameItems.length - 2 ] + "." + hostnameItems[ hostnameItems.length - 1 ];

                    cookie += ";domain=." + domain;
                }
            }

            if ( expiresIn != null ) {
                cookie += ";expires=" + new Date( new Date().getTime() + Times.getMillis( expiresIn ) );
            }

            if ( cookie != "" ) {
                document.cookie = cookie;
            }
        }
    }

    public get( key:string ): string {
        var result: string = null;

        if ( Cookies.instance !== undefined ) {
            for ( let i = 0; i < this.cookies.length; i++ ) {
                if ( this.cookies[i].getKey() == key ) {
                    result = this.cookies[i].getValue();
                    break;
                }
            }
        }

        return result;
    }

    public getAll(): Array<Pair<string, string>> {
        if ( Cookies.instance !== undefined ) {
            return this.cookies;
        } else {
            return null;
        }
    }
}