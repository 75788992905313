import { LogEntry } from "./LogEntry";
export class Logging {

    public static ERROR_LOG:Logging = new Logging( "Error Log", "red" );
    public static MODULES:Logging = new Logging( "Modul-Info", "green" );
    public static GENERAL_LOG:Logging = new Logging( "Allgemeiner Log", "blue" );

    private name:string;
    private color:string;

    private log:Array<LogEntry>;

    private constructor ( name:string, color:string ){
        this.name = name;
        this.color = color;
        this.log = new Array();
    }

    public getName ():string{
        return this.name;
    }

    public geColor ():string{
        return this.color;
    }

    public print ():void{
        console.log( "" );
        console.log( "%c********************" + "*".repeat( this.name.length ), "color: " + this.color + ";" );
        console.log( "%c*******   "+ this.name +"   *******", "color: " + this.color + ";" );
        console.log( "%c********************" + "*".repeat( this.name.length ), "color: " + this.color + ";" );
        for (let i = 0; i < this.log.length; i++) {
            if ( this.log[i].getOutputStyle() == "" ){
                console.log( this.log[i].getMessage() );
            } else {
                if ( this.log[i].getOutputStyle() instanceof Array ){
                    var args = new Array( this.log[i].getMessage() ).concat( this.log[i].getOutputStyle() );

                    console.log.apply( console, args );
                } else {
                    console.log( "%c" + this.log[i].getMessage(), this.log[i].getOutputStyle() );
                }
            }
        }
    }

    /** @Chris
     * @description print() method but for html
     * @param contentEle = contentHTMLElement
     */
    public debugPrint (contentEle:HTMLDivElement):void {
        const divEle:HTMLDivElement = document.createElement('div');
        divEle.style.color = this.color;
        divEle.innerHTML =`********************${"*".repeat( this.name.length )}`;
        contentEle.append(divEle);

        const divEle2:HTMLDivElement = document.createElement('div');
        divEle2.style.color = this.color;
        divEle2.innerHTML =`*******     ${this.name}     *******`;
        contentEle.append(divEle2);

        const divEle3:HTMLDivElement = document.createElement('div');
        divEle3.style.color = this.color;
        divEle3.innerHTML =`********************${"*".repeat( this.name.length )}`;
        contentEle.append(divEle3);

        for (let i = 0; i < this.log.length; i++) {
            if (this.log[i].getMessage() === '' && this.log[i].getOutputStyle() === '') { continue; }
            if ( this.log[i].getOutputStyle() == "" ) {
                let divEle1 = document.createElement('div');
                divEle1.innerHTML = this.log[i].getMessage();
                contentEle.append(divEle1);
            } else {
                // if multiple colors
                if ( this.log[i].getOutputStyle() instanceof Array ) {
                    var args = new Array( this.log[i].getMessage() ).concat( this.log[i].getOutputStyle() );
                    const divEle2 = document.createElement('div');

                    const spanEle = document.createElement('span');
                    spanEle.style.color = args[1].replace('color:', '').replace(';', '');
                    spanEle.innerHTML = args[0].split('%c')[1];

                    divEle2.append(spanEle);
                    const spanEle2 = document.createElement('span');
                    spanEle2.style.color = args[2].replace('color:', '').replace(';', '');
                    spanEle2.innerHTML = args[0].split('%c')[2];
                    divEle2.append(spanEle2);
                    contentEle.append(divEle2);
                }
                else {
                    let divEle3 = document.createElement('div');
                    divEle3.style.color = this.log[i].getOutputStyle();
                    divEle3.innerHTML =this.log[i].getMessage();
                    contentEle.append(divEle3);
                }
            }
        }
    }

    public add( message:string, outputStyle:string = "", useStrackTrace:boolean = true ):void{
        if ( useStrackTrace ){
            var error:Error = new Error( message );

            if ( typeof Error.captureStackTrace === "function"){
                Error.captureStackTrace( error, this.add );
                this.log.push( new LogEntry( error, outputStyle ) );
            } else {
                this.log.push( new LogEntry( error.stack, outputStyle ) );
            }
        } else {
            this.log.push( new LogEntry( message, outputStyle ) );
        }
    }

    public addWithMultipleStyles ( message:string, styles:Array<string> ):void {
        this.log.push( new LogEntry( message, styles ) );
    }

    public clear (){
        this.log = new Array();
    }
}