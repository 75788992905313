import { Pair } from "./Pair";

export class KeyEvents {
    private static instance: KeyEvents;

    private static events:Array<Pair<Array<number>, ( ...args:any[] ) => void>> = new Array();
    private static currentKeys:Array<boolean> = new Array();

    private constructor() {
        document.addEventListener("keydown", this.check.bind(this));
        document.addEventListener("keyup", this.check.bind(this));
    }

    public static start(): void {
        KeyEvents.instance = new KeyEvents();
    }

    private check( event: KeyboardEvent ): void {
        KeyEvents.currentKeys[ event.keyCode ] = ( event.type == "keydown" );

        for (let i = 0; i < KeyEvents.events.length; i++) {

            var validCount:number = 0;
            for (let j = 0; j < KeyEvents.events[i].getKey().length; j++) {
                validCount += ( KeyEvents.currentKeys[ KeyEvents.events[i].getKey()[j] ] ? 1 : 0 );
            }

            if ( validCount == KeyEvents.events[i].getKey().length ){
                KeyEvents.events[i].getValue()();
            }

        }
    }

    public static registerEvent( keyCodes:Array<number>, callback:( ...args:any[] ) => void ): void {
        if ( KeyEvents.instance !== undefined ) {
            KeyEvents.events.push( new Pair( keyCodes, callback ) );
        }
    }
}