import { UserAgent } from "../classes/Globals";

export class UserAgents {
    public static get(): UserAgent {
        if ( navigator.userAgent.match( /Chrome/i ) ) {
            return UserAgent.CHROME;
        } else if ( navigator.userAgent.match( /Safari/i ) ) {
            return UserAgent.SAFARI;
        } else if ( navigator.userAgent.match( /Opera/i ) ) {
            return UserAgent.OPERA;
        } else if ( navigator.userAgent.match( /Firefox/i ) ) {
            return UserAgent.FIREFOX;
        } else if ( navigator.userAgent.match( /MSIE/i ) ) {
            return UserAgent.IE;
        } else if ( navigator.userAgent.match( /Android/i ) ) {
            return UserAgent.ANDROID;
        } else if ( navigator.userAgent.match( /BlackBerry/i ) ) {
            return UserAgent.BLACK_BERRY;
        } else if ( navigator.userAgent.match( /iPhone|iPad|iPod/i ) ) {
            return UserAgent.IOS;
        } else if ( navigator.userAgent.match( /Opera Mini/i ) ) {
            return UserAgent.OPERA;
        } else if ( navigator.userAgent.match( /IEMobile/i ) ) {
            return UserAgent.IE;
        } else {
            return UserAgent.DEFAULT;
        }
    }

    public static getForKeyEvents(): UserAgent {
        if ( navigator.userAgent.match( /Firefox/i ) ) {
            return UserAgent.FIREFOX;
        } else if ( navigator.userAgent.match( /Safari/i ) ) {
            return UserAgent.SAFARI;
        } else if ( navigator.userAgent.match( /Opera/i ) ) {
            return UserAgent.OPERA;
        } else if ( navigator.userAgent.match( /MSIE/i ) ) {
            return UserAgent.IE;
        } else {
            return UserAgent.DEFAULT;
        }
    }

    public static getForTracking(): string {
        if ( navigator.userAgent.match( /Android/i ) ) {
            return UserAgent.ANDROID;
        } else if ( navigator.userAgent.match( /BlackBerry/i ) ) {
            return UserAgent.BLACK_BERRY;
        } else if ( navigator.userAgent.match( /iPhone|iPad|iPod/i ) ) {
            return UserAgent.IOS;
        } else if ( navigator.userAgent.match( /Opera Mini/i ) ) {
            return UserAgent.OPERA;
        } else if ( navigator.userAgent.match( /IEMobile/i ) ) {
            return UserAgent.IE;
        } else {
            return "Desktop";
        }
    }

    public static isMobile (): boolean{
        var match:RegExpMatchArray = navigator.userAgent.match( /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i );
       
        if ( match != null ){
            return match.length > 0;
        } else {
            return false;
        }
    }
}